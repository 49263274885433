@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Poppins', 'NanumSquareRound', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #35363a;
  background-size: contain;
}

input:read-only {
  background-color: #ddd;
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: local('Spoqa Han Sans Bold'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansBold.woff2') format('woff2'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansBold.woff') format('woff'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: local('Spoqa Han Sans Regular'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansRegular.woff2') format('woff2'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansRegular.woff') format('woff'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: local('Spoqa Han Sans Light'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansLight.woff2') format('woff2'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansLight.woff') format('woff'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: local('Spoqa Han Sans Thin'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansThin.woff2') format('woff2'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansThin.woff') format('woff'),
  url('/public/fonts/SpoqaHanSans/SpoqaHanSansThin.ttf') format('truetype');
}